import {Component, Vue} from 'vue-property-decorator';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {like} from '@/shared/like';
import {filtersFactory} from '@/shared/types/Filters';
import {DateRange} from '@/shared/types/DateRange';

@Component
export default class FilterRequests extends Vue {
    filters = filtersFactory();

    private readonly dayDuration = 24 * 60 * 60 * 1000;

    sortDesc = false;

    compareByStartDate(lrA: LeaveRequest, lrB: LeaveRequest): number {
        if (this.sortDesc) {
            return lrA.startDate.getTime() - lrB.startDate.getTime();
        } else {
            return lrB.startDate.getTime() - lrA.startDate.getTime();
        }
    }

    public isItemInDateRange({startDate, endDate}: DateRange): boolean {
        if (this.filters.startDate > this.filters.endDate) {
            return false;
        }

        const startsBefore = startDate <= this.filters.startDate;
        const endsAfter = endDate >= this.filters.endDate;

        if (startsBefore && endsAfter) {
            return true;
        }

        const startsIn = startDate >= this.filters.startDate && startDate <= this.filters.endDate;
        const endsIn = endDate.getTime() >= (this.filters.startDate.getTime() + this.dayDuration) && endDate <= this.filters.endDate;

        return startsIn || endsIn;
    }

    private isItemHasString(item: LeaveRequest, search: string): boolean {
        const likeTerm = like(search);
        return likeTerm(item.leaveSubtypeName || '')
            || likeTerm(item.notes)
            || likeTerm(item.employeeName || '')
            || likeTerm(item.declineReason || '');
    }

    filterItem(item: LeaveRequest): boolean {
        const isDateFilterPassed = this.isItemInDateRange(item);

        if (isDateFilterPassed) {
            return this.filters.search ? this.isItemHasString(item, this.filters.search) : true;
        } else {
            return false;
        }
    }
}
