import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {ID} from '@/shared/store/AbstractEntity';
import {Nullable} from '@/shared/types/Nullable';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {formatDate} from '@/shared/date/formatDate';

@Component({})
export class OneClick extends Mixins(ApiCall) {
    @Prop(Number)
    id!: ID;

    done = false;
    leaveRequest: Nullable<LeaveRequest> = null;

    isApprove = true;

    get reviewOtherUrl(): string {
        return this.$router.resolve({name: 'attendance-team'}).href;
    }

    get leaveDetails(): string {
        if (!this.leaveRequest) {
            return '';
        }

        const startDate = formatDate(this.leaveRequest.startDate);
        const endDate = formatDate(this.leaveRequest.endDate);
        const leaveType = this.leaveRequest.leaveSubtypeName;
        const name = this.leaveRequest.employeeName;

        const key = this.isApprove ? 'approvingLeaveRequestFor' : 'decliningLeaveRequestFor';

        return this.$t(key, {startDate, endDate, leaveType, name}) as string;
    }
}
