










import {Component, Prop, Vue} from 'vue-property-decorator';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';

@Component
export default class TypeSpecificFieldsValues extends Vue {
    @Prop(Object)
    item!: LeaveRequest;

    getTranslatedFieldValueString(value: string): string {
        const array = value.split(';');
        for(let i = 0; i<array.length; i++ ){
            // translate entries
            array[i] = this.$t(array[i]).toString();
        }
        return array?.join(',');
    }
}
