



















import {Component, Mixins} from 'vue-property-decorator';
import HashId from '@/shared/HashId.vue';
import {approveRequest, getLeaveRequest} from '@/attendance/shared/endpoints';
import {OneClick} from '@/attendance/shared/OneClick';
import '../shared/oneClick.styl';

@Component({
    components: {
        HashId,
    },
})
export default class ApproveById extends Mixins(OneClick) {
    async mounted(): Promise<void> {
        await this.apiCall(async () => {
            this.leaveRequest = await getLeaveRequest(this.id);
            await approveRequest(this.id);
        });

        if (!this.error) {
            this.done = true;
        }
    }
}
