

























import {Component, Mixins} from 'vue-property-decorator';
import HashId from '@/shared/HashId.vue';
import {declineRequest, getLeaveRequest} from '@/attendance/shared/endpoints';
import {OneClick} from '@/attendance/shared/OneClick';
import '../shared/oneClick.styl';

@Component({
    components: {
        HashId,
    },
})
export default class DeclineById extends Mixins(OneClick) {
    declineReason = '';

    isApprove = false;

    async mounted(): Promise<void> {
        await this.apiCall(async () => {
            this.leaveRequest = await getLeaveRequest(this.id);
        });
    }

    async onDeclineClick(): Promise<void> {
        await this.apiCall(declineRequest, this.id, this.declineReason);

        if (!this.error) {
            this.done = true;
        }
    }
}
