import { render, staticRenderFns } from "./DeclineById.vue?vue&type=template&id=2db3ec65&scoped=true&"
import script from "./DeclineById.vue?vue&type=script&lang=ts&"
export * from "./DeclineById.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db3ec65",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VIcon,VProgressLinear,VSheet,VTextField})
