





















import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import HmCol from '@/shared/HmCol.vue';
import LeaveTypeIcon from '@/attendance/shared/LeaveTypeIcon.vue';
import {LeaveType} from '@/dictionaries/leaveTypes/store';
import {getLeaveTypes} from '@/attendance/shared/endpoints';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getEmployeeLeaveTypes} from '@/employees/store/endpoints';
import {ID} from '@/shared/store/AbstractEntity';

@Component({
    components: {
        LeaveTypeIcon,
        HmCol,
    },
})
export default class AddLeave extends Mixins(ApiCall) {
    @Prop({type: Number})
    idAs!: ID;

    leaveTypesDict: LeaveType[] = [];

    async created(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            if (this.idAs !== -1) {
                this.leaveTypesDict = await getEmployeeLeaveTypes(this.idAs);
            } else {
                this.leaveTypesDict = await getLeaveTypes();
            }
        });
    }

    @Watch('idAs')
    async onIdAsChange(): Promise<void> {
        await this.fetchData();
    }
}
