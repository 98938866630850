



































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import {ID} from '@/shared/store/AbstractEntity';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getLeaveRequest} from '@/attendance/shared/endpoints';
import {Nullable} from '@/shared/types/Nullable';
import FileInput from '@/shared/FileInput.vue';

@Component({
    components: {
        FileInput,
    },
})
export default class RequestItemManagement extends Mixins(ApiCall) {
    @Prop(Number)
    id!: ID;

    item: Nullable<LeaveRequest> = null;

    intentDecline = false;
    reason = '';
    attachments: File[] = [];

    async mounted(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.item = await getLeaveRequest(this.id);
        });
    }

    get approvable(): boolean {
        if (!this.item) {
            return false;
        }

        return [
            LeaveRequestStatus.Declined,
            LeaveRequestStatus.Pending,
            LeaveRequestStatus.PreApproved,
        ].includes(this.item.leaveRequestStatus);
    }

    get declinable(): boolean {
        if (!this.item) {
            return false;
        }

        return [
            LeaveRequestStatus.Approved,
            LeaveRequestStatus.Pending,
            LeaveRequestStatus.PreApproved,
        ].includes(this.item.leaveRequestStatus);
    }

    get showApprove(): boolean {
        return !this.intentDecline && this.approvable;
    }

    get showDecline(): boolean {
        if (!this.item) {
            return false;
        }

        return !!this.item.canDecline && this.declinable;
    }

    get declineDisabled(): boolean {
        if (!this.item) {
            return true;
        }

        return this.intentDecline ? this.reason.trim().length === 0 : false;
    }

    @Watch('id')
    async onItemChange(): Promise<void> {
        await this.fetchData();
        this.attachments = [];
    }

    onDeclineClick(): void {
        if (this.intentDecline) {
            this.$emit('decline', this.id, this.reason);
        } else {
            this.intentDecline = true;
        }
    }
}
