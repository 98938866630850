










import {Component, Mixins, Prop} from 'vue-property-decorator';
import {HermesUser} from '@/employees/store/HermesUser';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getManageableEmployees} from '@/employees/store/endpoints';
import {InjectViewer} from '@/shared/mixins/InjectViewer';
import {ID} from '@/shared/store/AbstractEntity';

@Component
export default class RequestLeaveAs extends Mixins(ApiCall, InjectViewer) {
    @Prop(Number)
    value!: ID;

    manageableEmployees: HermesUser[] = [];

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.manageableEmployees = await getManageableEmployees();
        });
    }

    async created(): Promise<void> {
        await this.fetchData();
    }

    get items(): object[] {
        const result = [];

        for (const employee of this.manageableEmployees) {
            const value = employee.id;

            if (employee.id === this.viewer.id) {
                result.unshift({value, text: this.$t('addLeaveRequestAsMyself') as string});
            } else {
                result.push({value, text: employee.fullName});
            }
        }

        return result;
    }
}
