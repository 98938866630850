




















import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {ID} from '@/shared/store/AbstractEntity';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getRequestHistory} from '@/attendance/shared/endpoints';
import {formatDateTime} from '@/shared/date/formatDate';
import LeaveStatusIcon from '@/attendance/shared/LeaveStatusIcon.vue';
import {statusI18n} from '@/attendance/shared/statusI18n';

@Component({
    components: {
        LeaveStatusIcon,
    },
    filters: {
        formatDateTime,
        statusI18n,
    },
})
export default class RequestHistory extends Mixins(ApiCall) {
    @Prop(Number)
    id!: ID;

    history: object[] = [];

    async mounted(): Promise<void> {
        this.apiCall(this.fetchData, this.id);
    }

    @Watch('id')
    async onIdChange(newValue: ID): Promise<void> {
        this.apiCall(this.fetchData, newValue);
    }

    async fetchData(id: ID): Promise<void> {
        this.history = await getRequestHistory(id);
    }
}
