










import {Component, Mixins} from 'vue-property-decorator';
import Pending from './Pending.vue';
import Reviewed from './Reviewed.vue';
import TeamChart from './TeamChart.vue';
import {InjectViewer} from '@/shared/mixins/InjectViewer';

@Component({
    components: {
        TeamChart,
        Reviewed,
        Pending,
    },
})
export default class ManageableScreen extends Mixins(InjectViewer) {

}
