var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({staticClass:"elevation-0",attrs:{"headers":_vm.headers,"expanded":_vm.expanded,"loading":_vm.isFetching},on:{"update:expanded":function($event){return _vm.$emit('update:expanded', $event)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('request-item-management',{attrs:{"id":item.id},on:{"approve":_vm.onApprove,"decline":_vm.onDecline,"addFiles":_vm.onAddFiles}}),(item.typeSpecificFieldsValues)?_c('type-specific-fields-values',{attrs:{"item":item}}):_vm._e(),_c('request-history',{attrs:{"id":item.id}})],1)]}},{key:"header.dateRange",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.sortDesc !== null)?_c('flipping-button',{attrs:{"value":_vm.sortDesc,"icon":"sort"},on:{"input":function($event){return _vm.$emit('update:sortDesc', $event)}}}):_vm._e()]}},{key:"item.leaveRequestStatus",fn:function(ref){
var item = ref.item;
return [_c('leave-status-icon',{attrs:{"status":item.leaveRequestStatus}})]}},{key:"item.leaveTypeId",fn:function(ref){
var item = ref.item;
return [_c('leave-type-icon',{attrs:{"item":item,"no-tooltip":""}}),_vm._v(" "+_vm._s(item.leaveSubtypeName)+" ")]}},{key:"item.dateRange",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatRange")(item))+" ")]}},{key:"item.notesAndAttachments",fn:function(ref){
var item = ref.item;
return [_c('notes-and-attachments',{attrs:{"item":item}})]}},{key:"item.responsibleManagerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.responsibleManagerName)+" ")]}}])},'v-data-table',_vm.tableProps(_vm.items),false))}
var staticRenderFns = []

export { render, staticRenderFns }