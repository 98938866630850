






import {Component, Vue} from 'vue-property-decorator';
import {MetaInfo} from 'vue-meta';
import AppScreen from '@/layout/AppScreen.vue';

@Component({
    components: {
        AppScreen,
    },

    metaInfo(this: AttendanceScreen): MetaInfo {
        return {
            titleTemplate: '%s — HERMES',
            title: this.$t('menuAbsences') as string,
        };
    },
})
export default class AttendanceScreen extends Vue {
}
