






















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {IsFullscreen} from '@/shared/mixins/IsFullscreen';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {formatDate} from '@/shared/date/formatDate';
import AppDialog from '@/shared/AppDialog.vue';

@Component({
    components: {
        AppDialog,
    },
})
export default class ConfirmWithdraw extends Mixins(IsFullscreen) {
    @Prop(Object)
    request!: LeaveRequest;

    get dlgBody(): string {
        return this.$t('areYouSureToWithdrawRequest', {
            name: this.request.leaveSubtypeName,
            start: formatDate(this.request.startDate),
            end: formatDate(this.request.endDate),
        }) as string;
    }
}
