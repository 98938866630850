















































import {Component, InjectReactive, Mixins, Watch} from 'vue-property-decorator';
import {formatRange} from '@/attendance/shared/formatRange';
import {TableHeader} from '@/shared/types/TableHeader';
import ApproveAll from '@/shared/ApproveAll.vue';
import {approveAllRequests} from '@/attendance/shared/endpoints';
import {ManageableList} from '@/attendance/shared/ManageableList';
import ManageableGrid from '@/attendance/shared/ManageableGrid.vue';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {MeUser} from '@/employees/store/HermesUser';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import FilterForm from '@/shared/FilterForm.vue';

@Component({
    components: {
        FilterForm,
        ManageableGrid,
        ApproveAll,
    },
    filters: {
        formatRange,
    },
})
export default class Pending extends Mixins(ManageableList) {
    @InjectReactive()
    readonly viewer!: MeUser;

    get headers(): TableHeader<LeaveRequest>[] {
        const h: TableHeader<LeaveRequest>[] = [
            {
                text: this.$t('leaveEmployee') as string,
                value: 'employeeName',
            },
            {
                text: this.$t('leaveType') as string,
                value: 'leaveTypeId',
            },
            {
                text: this.$t('leaveDateRange') as string,
                value: 'dateRange',
            },
            {
                text: this.$t('leaveDuration') as string,
                value: 'workingDays',
            },
        ];

        if (this.viewer.isAdmin) {
            h.push({
                text: this.$t('leaveResponsibleManager') as string,
                value: 'responsibleManagerName',
            });
        }

        h.push({
            text: this.$t('leaveNotesAndAttachments') as string,
            value: 'notesAndAttachments',
        });

        return h;
    }

    visibleStatuses = [
        LeaveRequestStatus.Pending,
        LeaveRequestStatus.Approved,
        LeaveRequestStatus.Declined,
        LeaveRequestStatus.PreApproved,
        LeaveRequestStatus.Withdrawn,
    ];

    isPending = true;

    confirmAmount = -1;

    preApprovedOnly = false;

    async onConfirmApproveAll(): Promise<void> {
        this.confirmAmount = -1;

        await this.apiCall(approveAllRequests);

        this.askParentToUpdate();
    }

    onCancelApprove(): void {
        this.error = '';
        this.confirmAmount = -1;
    }

    @Watch('preApprovedOnly')
    onPreApprovedOnlyChange(newValue: boolean): void {
        if (newValue) {
            this.visibleStatuses = [LeaveRequestStatus.PreApproved];
        } else {
            this.visibleStatuses = [
                LeaveRequestStatus.Pending,
                LeaveRequestStatus.Approved,
                LeaveRequestStatus.Declined,
                LeaveRequestStatus.PreApproved,
                LeaveRequestStatus.Withdrawn,
            ];
        }
    }
}
