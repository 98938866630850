
























import {Component, Mixins} from 'vue-property-decorator';
import {TableHeader} from '@/shared/types/TableHeader';
import {formatRange} from '@/attendance/shared/formatRange';
import FilterForm from '@/shared/FilterForm.vue';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {ManageableList} from '@/attendance/shared/ManageableList';
import ManageableGrid from '@/attendance/shared/ManageableGrid.vue';
import ExpandButton from '@/shared/ExpandButton.vue';

@Component({
    components: {
        ExpandButton,
        ManageableGrid,
        FilterForm,
    },
    filters: {
        formatRange,
    },
})
export default class Reviewed extends Mixins(ManageableList) {
    get headers(): TableHeader<LeaveRequest>[] {
        const h = [
            {
                text: this.$t('leaveEmployee') as string,
                value: 'employeeName',
            },
            {
                text: this.$t('leaveStatus') as string,
                value: 'leaveRequestStatus',
            },
            {
                text: this.$t('leaveType') as string,
                value: 'leaveTypeId',
            },
            {
                text: this.$t('leaveDateRange') as string,
                value: 'dateRange',
            },
            {
                text: this.$t('leaveDuration') as string,
                value: 'workingDays',
            },
        ];

        if (this.viewer.isAdmin) {
            h.push({
                text: this.$t('leaveResponsibleManager') as string,
                value: 'responsibleManagerName',
            });
        }

        h.push({
            text: this.$t('leaveNotesAndAttachments') as string,
            value: 'notesAndAttachments',
        });

        return h;
    }
}
