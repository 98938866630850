import {BackendUser, HermesUser} from '@/employees/store/HermesUser';
import {api} from '@/shared/api';
import {converter} from './converter';
import {LeaveType} from '@/dictionaries/leaveTypes/store';
import {ID} from '@/shared/store/AbstractEntity';

export async function getManageableEmployees(): Promise<HermesUser[]> {
    const {data} = await api<BackendUser<string>[]>({
        url: 'Employees/activeEmployees',
    });

    return data.map(converter.hydrate) as HermesUser[];
}

export async function getEmployeeLeaveTypes(id: ID): Promise<LeaveType[]> {
    const {data} = await api<LeaveType[]>({
        url: `Employees/leaveTypes/${id}`,
    });

    return data;
}
