





















































































import {Component, Inject, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import DatePicker from '@/shared/date/DatePicker.vue';
import DistinctCard from '@/shared/DistinctCard.vue';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import {Nullable} from '@/shared/types/Nullable';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {dateToDayStart} from '@/shared/date/dateToDayStart';
import {LeaveSubType} from '@/dictionaries/leaveTypes/store';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {sendRequest} from '@/attendance/shared/endpoints';
import {Validatable} from '@/shared/types/Validatable';
import {ID} from '@/shared/store/AbstractEntity';
import FileInput from '@/shared/FileInput.vue';
import {getLeaveRequestTypeSpecificFieldsById} from '@/system/leaveRequestTypeSpecificFields/store/endpoints';
import {
    LeaveRequestTypeSpecificFields,
    TypeSpecificFieldValue,
    LeaveRequestTypeSpecificFieldsEnum,
} from '@/system/leaveRequestTypeSpecificFields/store/LeaveRequestTypeSpecificFields';
@Component({
    components: {
        DistinctCard,
        DatePicker,
        FileInput,
    },
})
export default class LeaveForm extends Mixins(InputAttrs, ApiCall) {
    @Prop(Object)
    protected readonly leaveType!: LeaveSubType;

    @Prop(Number)
    protected readonly employeeId!: ID;

    @Inject()
    readonly bus!: Vue;

    @Watch('leaveType')
    async onLeaveTypeChange(): Promise<void> {
        await this.initForm();
    }

    protected valid = false;

    protected startDate: Date = dateToDayStart();

    protected endDate: Nullable<Date> = null;

    protected notes = '';

    protected attachments: File[] = [];

    protected typeSpecificFields: LeaveRequestTypeSpecificFields[] = [];

    protected typeSpecificFieldsValues: TypeSpecificFieldValue[] = [];

    protected fieldTypes = LeaveRequestTypeSpecificFieldsEnum;

    async mounted(): Promise<void> {
        await this.initForm();
    }

    async initForm(): Promise<void> {
        (this.$refs.form as Vue & Validatable).validate();
        this.valid = false;
        this.startDate = dateToDayStart();
        this.endDate = null;
        this.notes = '';
        this.attachments = [];
        this.typeSpecificFields = await this.getTypeSpecificFieldsById(this.leaveType.id);
        this.createTypeSpecificFieldsValues();
    }

    protected createTypeSpecificFieldsValues() {
        this.typeSpecificFieldsValues = this.typeSpecificFields.map(field => ({
            fieldId: field.id,
            fieldName: field.fieldName,
            fieldValue: '',
        }));
    }

    protected updateTypeSpecificFieldValue(field: LeaveRequestTypeSpecificFields, value: string) {
        const index = this.typeSpecificFieldsValues.findIndex(item => item.fieldName === field.fieldName);
        if (index >= 0) {
            this.typeSpecificFieldsValues[index].fieldValue = value.toString();
        }
    }

    protected updateTypeSpecificFieldOption(field: LeaveRequestTypeSpecificFields, value: string[]) {
        const index = this.typeSpecificFieldsValues.findIndex(item => item.fieldName === field.fieldName);
        if (index >= 0) {
            this.typeSpecificFieldsValues[index].fieldValue = value?.join(';');
        }
    }

    ruleDateMandatory(value: Nullable<Date>): string | boolean {
        return value === null ? this.$t('dateIsMandatory') as string : true;
    }

    ruleEndDateNotInPast(value: Date): string | boolean {
        return value < this.startDate ? this.$t('endBeforeStart') as string : true;
    }

    getTypeSpecificFieldsById(leaveSubtypeId: number): Promise<LeaveRequestTypeSpecificFields[]>{
        return getLeaveRequestTypeSpecificFieldsById(leaveSubtypeId);
    }

    async onSubmit(): Promise<void> {
        const formData = new FormData();

        // add to form single fields from the following object

        const request: Partial<LeaveRequest<string>> = {
            leaveSubtypeId: this.leaveType.id,
            employeeId: this.employeeId,
            startDate: this.startDate.toISOString(),
            endDate: (this.endDate as Date).toISOString(),
            notes: this.notes,
        };

        for (const [key, value] of Object.entries(request)) {
            formData.append(key, value?.toString() || '');
        }

        // add to form array field - attachments

        for (const file of this.attachments) {
            formData.append('attachments', file);
        }

        // add to form array field - typeSpecificFieldsValues

        for (const typeSpecificValue of this.typeSpecificFieldsValues) {
            formData.append('typeSpecificFieldsValues', JSON.stringify(typeSpecificValue));
        }

        await this.apiCall(sendRequest, formData);

        if (!this.error) {
            this.bus.$emit('refreshRequests');
        }
    }
}
