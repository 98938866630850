var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-4 requestsList",attrs:{"elevation":"2"}},[_c('header',{staticClass:"headline mb-5"},[_vm._v(" "+_vm._s(_vm.$t('leaveRequestsHistory'))+" "),_c('expand-button',{staticClass:"hidden-lg-and-up",model:{value:(_vm.filtersExpanded),callback:function ($$v) {_vm.filtersExpanded=$$v},expression:"filtersExpanded"}})],1),_c('filter-form',{attrs:{"expanded":_vm.filtersExpanded},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('v-data-table',_vm._b({staticClass:"elevation-0",attrs:{"headers":_vm.headers,"expanded":_vm.expanded,"loading":_vm.isFetching},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.leaveRequestStatus",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-4",attrs:{"cols":"1"}},[_c('leave-status-icon',{attrs:{"status":item.leaveRequestStatus}})],1),(item.declineReason)?_c('v-col',[_vm._v(_vm._s(item.declineReason))]):_vm._e(),(item.canWithdraw)?_c('v-col',[_c('v-btn',{attrs:{"color":"warning","x-small":"","outlined":""},on:{"click":function($event){_vm.confirmWithdrawItem = item}}},[_vm._v(" "+_vm._s(_vm.$t('leaveRequestWithdraw'))+" ")])],1):_vm._e()],1)]}},{key:"item.leaveTypeId",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('leave-type-icon',{attrs:{"item":item,"large":false,"no-tooltip":""}})],1),_c('v-col',[_c('small',[_vm._v(_vm._s(item.leaveSubtypeName))])])],1)]}},{key:"item.dateRange",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatRange")(item))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[(item.typeSpecificFieldsValues[0])?_c('type-specific-fields-values',{attrs:{"item":item}}):_vm._e(),_c('notes-and-attachments',{attrs:{"item":item}}),_c('request-history',{attrs:{"id":item.id}})],1)]}}])},'v-data-table',_vm.tableProps(_vm.list),false)),(_vm.confirmWithdrawItem)?_c('confirm-withdraw',{attrs:{"request":_vm.confirmWithdrawItem},on:{"cancel":function($event){_vm.confirmWithdrawItem = null},"confirm":function($event){return _vm.withdrawRequest(_vm.confirmWithdrawItem.id)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }