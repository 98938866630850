













































import {Component, Mixins, Provide, Vue} from 'vue-property-decorator';

import HmCol from '@/shared/HmCol.vue';
import AddLeave from '@/attendance/AddLeave.vue';
import LeaveForm from '@/attendance/LeaveForm.vue';
import RequestsList from '@/attendance/RequestsList.vue';
import LeaveStats from '@/attendance/LeaveStats.vue';
import {Nullable} from '@/shared/types/Nullable';
import {LeaveSubType} from '@/dictionaries/leaveTypes/store';
import RequestLeaveAs from '@/attendance/RequestLeaveAs.vue';
import {InjectViewer} from '@/shared/mixins/InjectViewer';
import {ID} from '@/shared/store/AbstractEntity';

@Component({
    components: {
        RequestLeaveAs,
        LeaveStats,
        RequestsList,
        LeaveForm,
        AddLeave,
        HmCol,
    },
})
export default class Request extends Mixins(InjectViewer) {
    protected leaveType: Nullable<LeaveSubType> = null;
    protected isRequestSubmitted = false;

    idAs: ID = -1;

    @Provide()
    private bus = new Vue();

    protected onRequestLeave(leaveType: Nullable<LeaveSubType>): void {
        this.leaveType = leaveType;
        this.isRequestSubmitted = false;
    }

    mounted(): void {
        this.bus.$on('refreshRequests', this.onRequestSubmitted);
        this.idAs = this.viewer.id;
    }

    onRequestSubmitted(): void {
        this.isRequestSubmitted = true;
    }
}
