import {LeaveRequestTypeSpecificFields, LeaveRequestTypeSpecificFieldAdd} from '@/system/leaveRequestTypeSpecificFields/store/LeaveRequestTypeSpecificFields';
import {api} from '@/shared/api';
import {LeaveSubtype} from '@/system/leaveRequestTypeSpecificFields/store/LeaveSubtype';

export async function listLeaveRequestTypeSpecificFields(): Promise<LeaveRequestTypeSpecificFields[]> {
    const {data} = await api<LeaveRequestTypeSpecificFields[]>({
        method: 'get',
        url: 'leaveRequests/specificFields/all',
    });

    return data;
}

export async function getLeaveRequestTypeSpecificFieldsById(leaveSubtypeId: number): Promise<LeaveRequestTypeSpecificFields[]> {
    const {data} = await api<LeaveRequestTypeSpecificFields[]>({
        method: 'get',
        url: 'leaveRequests/specificFields',
        params: { leaveSubtypeId: leaveSubtypeId },
    });

    return data;
}

export async function createLeaveRequestTypeSpecificField(LeaveRequestTypeSpecificField: LeaveRequestTypeSpecificFieldAdd): Promise<LeaveRequestTypeSpecificFields> {
    const {data} = await api<LeaveRequestTypeSpecificFields>({
        method: 'post',
        url: 'leaveRequests/specificFields',
        data: LeaveRequestTypeSpecificField,
    });

    return data;
}

export async function updateLeaveRequestTypeSpecificField(LeaveRequestTypeSpecificField: LeaveRequestTypeSpecificFields): Promise<void> {
    await api<LeaveRequestTypeSpecificFields>({
        method: 'put',
        url: 'leaveRequests/specificFields',
        data: LeaveRequestTypeSpecificField,
    });
}

export async function deleteLeaveRequestTypeSpecificField(typeSpecificFieldId: number): Promise<void> {
    await api<LeaveRequestTypeSpecificFields>({
        method: 'delete',
        url: `leaveRequests/specificFields/${typeSpecificFieldId}`,
    });
}

export async function listLeaveSubtypes(): Promise<LeaveSubtype[]> {
    const {data} = await api<LeaveSubtype[]>({
        method: 'get',
        url: 'leaveRequests/leaveSubtypes',
    });

    return data;
}
