import {AbstractEntity} from '@/shared/store/AbstractEntity';

export interface LeaveRequestTypeSpecificFields extends AbstractEntity {
    leaveSubtypeId: number;
    fieldName: string;
    fieldType: LeaveRequestTypeSpecificFieldsEnum;
    fieldOptions: string[];
    isRequired: boolean;
}

export interface TypeSpecificFieldValue {
    fieldId: number;
    fieldName: string;
    fieldValue: string;
}

export type LeaveRequestTypeSpecificFieldAdd = Omit<LeaveRequestTypeSpecificFields, 'id'>;

export enum LeaveRequestTypeSpecificFieldsEnum {
    Text,
    CheckBox,
    Select,
    MultiSelect
}

export function isValidLeaveRequestTypeSpecificField(value: LeaveRequestTypeSpecificFields | LeaveRequestTypeSpecificFieldAdd): boolean {
    return value.fieldName !== ''
        && value.fieldName !== undefined
        && Object.values(LeaveRequestTypeSpecificFieldsEnum).includes(value.fieldType)
        && value.leaveSubtypeId !== null;
}
