




































































import {Component, Inject, Mixins, Prop, Vue} from 'vue-property-decorator';
import {getMyRequests, withdrawRequest} from '@/attendance/shared/endpoints';
import {ID} from '@/shared/store/AbstractEntity';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {TableHeader} from '@/shared/types/TableHeader';
import LeaveStatusIcon from '@/attendance/shared/LeaveStatusIcon.vue';
import LeaveTypeIcon from '@/attendance/shared/LeaveTypeIcon.vue';
import {formatRange} from '@/attendance/shared/formatRange';
import NotesAndAttachments from '@/attendance/shared/NotesAndAttachments.vue';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import FilterRequests from '@/attendance/shared/FilterRequests';
import FilterForm from '@/shared/FilterForm.vue';
import RequestHistory from '@/attendance/shared/RequestHistory.vue';
import LeaveBadge from '@/attendance/shared/LeaveBadge.vue';
import {Nullable} from '@/shared/types/Nullable';
import ConfirmWithdraw from '@/attendance/ConfirmWithdraw.vue';
import {DataTableProps} from '@/shared/mixins/DataTableProps';
import {ApiCall} from '@/shared/mixins/ApiCall';
import ExpandButton from '@/shared/ExpandButton.vue';
import {FiltersExpanded} from '@/shared/mixins/FiltersExpanded';
import TypeSpecificFieldsValues from '@/attendance/shared/TypeSpecificFieldsValues.vue';

@Component({
    components: {
        TypeSpecificFieldsValues,
        ExpandButton,
        ConfirmWithdraw,
        LeaveBadge,
        RequestHistory,
        FilterForm,
        NotesAndAttachments,
        LeaveTypeIcon,
        LeaveStatusIcon,
    },
    filters: {
        formatRange,
    },
})
export default class RequestsList extends Mixins(FilterRequests, DataTableProps, ApiCall, FiltersExpanded) {
    @Prop(Number)
    readonly employeeId!: ID;

    @Inject()
    readonly bus!: Vue;

    isFetching = false;
    error = '';

    LeaveRequestStatus = LeaveRequestStatus;

    rawList: LeaveRequest[] = [];

    confirmWithdrawItem: Nullable<LeaveRequest> = null;

    get headers(): Array<TableHeader<LeaveRequest>> {
        return [
            {
                text: this.$t('leaveStatus') as string,
                value: 'leaveRequestStatus',
            },
            {
                text: this.$t('leaveType') as string,
                value: 'leaveTypeId',
            },
            {
                text: this.$t('leaveDateRange') as string,
                value: 'dateRange',
            },
            {
                text: this.$t('leaveDuration') as string,
                value: 'workingDays',
            },
        ];
    }

    expanded = [];

    async mounted(): Promise<void> {
        await this.fetchList();

        this.bus.$on('refreshRequests', this.fetchList);
    }

    async fetchList(): Promise<void> {
        await this.apiCall(async () => {
            this.rawList = await getMyRequests(this.employeeId);
        });
    }

    async withdrawRequest(id: ID): Promise<void> {
        this.confirmWithdrawItem = null;

        await this.apiCall(withdrawRequest, id);
        this.bus.$emit('refreshRequests');
    }

    get list(): LeaveRequest[] {
        if (!this.filters.search && !this.filters.startDate && !this.filters.endDate) {
            return this.rawList;
        } else {
            return this.rawList.filter(this.filterItem);
        }
    }
}
