import {Component, Mixins} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {
    approveRequest,
    declineRequest,
    getManageableRequests,
    uploadAttachments,
} from '@/attendance/shared/endpoints';
import {ID} from '@/shared/store/AbstractEntity';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import FilterRequests from '@/attendance/shared/FilterRequests';
import {InjectViewer} from '@/shared/mixins/InjectViewer';
import {FiltersExpanded} from '@/shared/mixins/FiltersExpanded';

@Component({})
export class ManageableList extends Mixins(ApiCall, FilterRequests, InjectViewer, FiltersExpanded) {
    requests: LeaveRequest[] = [];

    expanded = [];

    isPending = false;

    visibleStatuses: LeaveRequestStatus[] = [LeaveRequestStatus.Pending,
        LeaveRequestStatus.Approved,
        LeaveRequestStatus.Declined,
        LeaveRequestStatus.PreApproved,
        LeaveRequestStatus.Withdrawn];

    async mounted(): Promise<void> {
        await this.fetchRequests();

        this.$parent.$on('update', this.fetchRequests);
    }

    askParentToUpdate(): void {
        this.$parent.$emit('update');
    }

    async fetchRequests(): Promise<void> {
        await this.apiCall(async () => {
            this.requests = await getManageableRequests(this.isPending);
        });
    }

    updateInList(updatedItem: LeaveRequest): void {
        for (const [index, item] of this.requests.entries()) {
            if (item.id === updatedItem.id) {
                this.$set(this.requests, index, updatedItem);
                break;
            }
        }
    }

    async onApprove(id: ID): Promise<void> {
        this.expanded = [];

        await this.apiCall(approveRequest, id);

        this.askParentToUpdate();
    }

    async onDecline(id: ID, reason: string): Promise<void> {
        this.expanded = [];

        await this.apiCall(declineRequest, id, reason);

        this.askParentToUpdate();
    }

    async onAddFiles(id: ID, attachments: []): Promise<void> {
        await this.apiCall(async () => {
            const updatedItem = await uploadAttachments(id, attachments);
            this.updateInList(updatedItem);
        });

        this.expanded = [];
    }

    get list(): LeaveRequest[] {
        let result = this.requests.filter(lr => this.visibleStatuses.includes(lr.leaveRequestStatus));

        if (this.filters.search || this.filters.startDate || this.filters.endDate) {
            result = result.filter(this.filterItem);
        }

        return result.sort(this.compareByStartDate);
    }
}
