























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import RequestItemManagement from '@/attendance/shared/RequestItemManagement.vue';
import RequestHistory from '@/attendance/shared/RequestHistory.vue';
import LeaveStatusIcon from '@/attendance/shared/LeaveStatusIcon.vue';
import LeaveTypeIcon from '@/attendance/shared/LeaveTypeIcon.vue';
import NotesAndAttachments from '@/attendance/shared/NotesAndAttachments.vue';
import {formatRange} from '@/attendance/shared/formatRange';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {ID} from '@/shared/store/AbstractEntity';
import {TableHeader} from '@/shared/types/TableHeader';
import {DataTableProps} from '@/shared/mixins/DataTableProps';
import FlippingButton from '@/shared/FlippingButton.vue';
import {Nullable} from '@/shared/types/Nullable';
import TypeSpecificFieldsValues from '@/attendance/shared/TypeSpecificFieldsValues.vue';

@Component({
    components: {
        TypeSpecificFieldsValues,
        FlippingButton,
        NotesAndAttachments,
        LeaveTypeIcon,
        LeaveStatusIcon,
        RequestHistory,
        RequestItemManagement,
    },
    filters: {
        formatRange,
    },
})
export default class ManageableGrid extends Mixins(DataTableProps) {
    @Prop(Array)
    items!: LeaveRequest[];

    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Array)
    headers!: TableHeader<LeaveRequest>[];

    @Prop(Array)
    expanded!: unknown[];

    @Prop({type: Boolean, default: null})
    sortDesc!: Nullable<boolean>;

    onApprove(id: ID): void {
        this.$emit('approve', id);
    }
    onDecline(id: ID, reason: string): void {
        this.$emit('decline', id, reason);
    }
    onAddFiles(id: ID, attachments: File[]): void {
        this.$emit('addFiles', id, attachments);
    }
}
