









import {Component, Prop, Vue} from 'vue-property-decorator';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';

@Component
export default class NotesAndAttachments extends Vue {
    @Prop(Object)
    item!: LeaveRequest;
}
